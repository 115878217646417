<template>
  <v-theme-provider dark>
    <section
      id="features"
      class="secondary"
    >
      <v-img
        :src="require('@/assets/background/3_2.png')"
        :max-height="maxHeight"
        gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
      >
        <base-section-heading
          title="Products"
          class="transparent mt-12"
        >
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, in! Asperiores, impedit libero. Veniam rerum saepe unde nihil possimus quibusdam esse accusamus mollitia magni fuga. -->
        </base-section-heading>

        <v-responsive
          class="mx-auto mt-12"
          max-width="1350"
        >
          <v-container fluid>
            <v-row>
              <v-col
                v-for="card in cards"
                :key="card.title"
                cols="12"
                sm="6"
              >
                <a
                  :href="card.link"
                  target="_blank"
                >
                  <base-info-card
                    align="center"
                    dark
                    color="white"
                    v-bind="card"
                  />
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-responsive>
      </v-img>
    </section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-radar',
          title: 'Position++',
          text: 'Cloud-based GNSS monitoring service',
          link: 'http://positionplusplus.com/',
        },
        // {
        //   icon: 'mdi-camera-outline',
        //   title: 'Photography',
        //   text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        // },
        // {
        //   icon: 'mdi-pencil-outline',
        //   title: 'Brand Making',
        //   text: 'Efficiently unleash media information without cross-media value. Quickly maximize value timely deliverables schemas.',
        // },
        {
          icon: 'mdi-webhook',
          title: 'DynaWeb',
          text: 'Cloud-based survey network adjustment service',
          link: 'https://dynaweb.positionplusplus.com/',
        },
      ],
    }),

    computed: {
      maxHeight () {
        return this.$vuetify.breakpoint.mdAndUp ? '400' : '700'
      },
    },
  }
</script>
